<template>
  <AppPage :title="$vuetify.lang.t('$vuetify.seller_all_pets')" :loading="loading">
    <div class="images" v-if="!loading && myPost.length">
      <template v-for="post in myPost">
        <v-img
          :src="post.media[0]"
          v-if="!isVideo(post.media[0])"
          class="image"
          :key="post.id"
          height="auto"
          max-height="130px"
          width="100%"
          center
          v-ripple
          @click="$router.push(`/pet-detail/${post.id}`)"
        >
          <template v-slot:placeholder>
            <loader loading size="30" height="100%" color="primary"> </loader>
          </template>
        </v-img>
        <VideoPlayer
          :key="post.id"
          :src="post.media[0] + '#t=0.1'"
          max-height="130px"
          width="100%"
          rounded
          no-play
          v-else
          @clicked="$router.push(`/pet-detail/${post.id}`)"
        />
      </template>
    </div>

    <div v-else class="text-center">
      <p class="subtitle-1 my-5">
        {{ $vuetify.lang.t("$vuetify.no_post_at_the_moment") }}
      </p>
    </div>

    <div v-intersect="infiniteScrolling"></div>
  </AppPage>
</template>

<script>
import VideoPlayer from "@/components/media/video-player";
import { createNamespacedHelpers } from "vuex";
const feedModule = createNamespacedHelpers("sellerPets");
import { VIDEO_EXTENSIONS_ARRAY } from "@/constants";
export default {
  name: "SellerPosts",
  components: { VideoPlayer },
  data() {
    return{
        sellerID: null
    };
  },
  mounted() {
    this.sellerID = this.$route.query.seller_id;
  },
  computed: {
    ...feedModule.mapState(["myPost", "myPostNextPage", "loading"]),
  },
  methods: {
    ...feedModule.mapActions(["FETCH_MY_POST"]),
    ...feedModule.mapMutations(["CLEAR_POSTS"]),
    infiniteScrolling(entries, observer, isIntersecting) {
      if (this.myPostNextPage && !this.loading && isIntersecting) {
        this.FETCH_MY_POST(this.sellerID);
      }
    },
    isVideo(src) {
      if (typeof src === "string") {
        let strParts = src.split(".");
        let extension = strParts[strParts.length - 1];
        return VIDEO_EXTENSIONS_ARRAY.includes(extension.toLowerCase())
          ? true
          : false;
      }
    },
  },
  created() {},
  beforeDestroy() {
    this.CLEAR_POSTS();
  },
};
</script>

<style lang="scss" scoped>
.images {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.5rem;
  padding: 0.5rem;

  .image {
    border-radius: 10px;
  }
}
</style>
